import { hasNoValue } from "components/inputs/inputHelpers";

export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};
// Anything above this should be a special request
export const MAX_EXPORT_DATA_COUNT = 10000;

export const systemRoles = {
  // ioTec id user roles
  ADMIN: 'ADMIN',
  SUPER: 'SUPER',
  PRIMARY: 'PRIMARY',
};

export const systemAdminRoles = [
  systemRoles.ADMIN,
  systemRoles.SUPER
];

export const primaryRoles = [
  systemRoles.PRIMARY,
  systemRoles.ADMIN,
  systemRoles.SUPER
];

export const rootAdminUserRoles = [
  systemRoles.ADMIN,
  systemRoles.SUPER
];

export const appPermissions = {
  CREDIT_REFERENCE_TRIGGER: 'CREDIT_REFERENCE_TRIGGER',
  CREDIT_REFERENCE_VIEW: 'VERIFICATION_VIEW',
  PARTNERS_EDIT: 'PARTNERS_EDIT',
  PARTNERS_VIEW: 'PARTNERS_VIEW',
  PARTNER_TOPUP: 'PARTNER_TOPUP',
  PARTNER_MEMBERS_EDIT: 'PARTNER_MEMBERS_EDIT',
  PARTNER_MEMBERS_VIEW: 'PARTNER_MEMBERS_VIEW',
  API_KEY_EDIT: 'API_KEY_EDIT',
  CUSTOMERS_EDIT: 'CUSTOMERS_EDIT',
  CUSTOMERS_VIEW: 'CUSTOMERS_VIEW',
  CRB_CONFIG_EDIT: 'CRB_CONFIG_EDIT',
  CRB_CONFIG_VIEW: 'CRB_CONFIG_VIEW',
  SETTINGS_EDIT: 'SETTINGS_EDIT',
  PARTNER_BILLING_VIEW: 'PARTNER_BILLING_VIEW',
};

export const hasAllRoles = (user: any, roles: string[] = []): boolean => {

  const roleData = user.role || user.roles;
  if (hasNoValue(roleData)) return false;

  const rolesList = roles.map((it) => it.toLowerCase());

  if (typeof roleData === 'string') {
    const userRole = roleData.toLowerCase();
    return rolesList.includes(userRole);
  }

  const rolesData = roleData?.map((it: any) => it.toLowerCase()) ?? [];
  return rolesList.every((r: any) => rolesData.includes(r));
};

export const hasAnyRole = (user: any, roles: string[] = []): boolean => {
  const roleData = user.role || user.roles;
  const rolesList = roles.map((it) => it.toLocaleLowerCase());
  if (typeof roleData === 'string') {
    const userRole = roleData.toLocaleLowerCase();
    return rolesList.indexOf(userRole) >= 0;
  }
  const rolesData = roleData?.map((it: any) => it.toLocaleLowerCase()) ?? [];
  return rolesData.some((r: any) => rolesList.indexOf(r) >= 0);
};

export const isRootAdminUser = (user: any): boolean => hasAnyRole(user, rootAdminUserRoles);

export const canEditStaff = (user: any): boolean => hasAnyRole(user, [appPermissions.PARTNER_MEMBERS_EDIT]);
export const canViewPartners = (user: any): boolean => hasAnyRole(user, [appPermissions.PARTNERS_VIEW]);


export const localRoutes = {
  // credit check
  creditChecks: '/credit-checks',
  creditScoreCheck: '/credit-score-check-info',
  creditScoreCheckDetails: '/credit-score-check-info/:ScoreCheckId',
  creditScoreCheckCreate: '/credit-score-check-info/create',

  // loan data submission
  dataSubmissions: '/data-submissions',
  dataSubmissionDetails: '/data-submissions/:bulkId',
  dataSubmissionUpload: '/data-submissions/upload',

  staff: '/staff',
  staffDetails: '/staff/:staffId',

  partners: '/partners',
  partnerDetails: '/partners/:partnerId',

  settings: '/settings',
  invoices: '/invoices',
  priceBands: '/price-bands',
};

const servers: any = {
  dev: {
    Pay: 'https://pay-staging.iotec.io',
    Verify: 'https://verify-staging.iotec.io',
    Messaging: 'https://messaging-staging.iotec.io',
    Auth: 'https://id.iotec.io',
    Crm: 'https://iotec-crm-staging.azurewebsites.net',
    Lumen: 'https://localhost:8208',
  },
  staging: {
    Pay: 'https://pay-staging.iotec.io',
    Verify: 'https://verify-staging.iotec.io',
    Messaging: 'https://messaging-staging.iotec.io',
    Auth: 'https://id.iotec.io',
    Crm: 'https://iotec-crm-staging.azurewebsites.net',
    Lumen: 'https://lumen-staging.iotec.io',
  },
  production: {
    Pay: 'https://pay.iotec.io',
    Verify: 'https://verify.iotec.io',
    Messaging: 'https://messaging.iotec.io',
    Auth: 'https://id.iotec.io',
    Crm: 'https://crm.iotec.io',
    Lumen: 'https://lumen.iotec.io',
  }
};

const evVar = process.env.REACT_APP_ENV || 'dev';

const environment = evVar.trim();
console.log(`############# Env : ${environment} ################`);

const env = servers[environment];
const authUrl = env.Auth;
const crmUrl = env.Crm;
const lumenUrl = env.Lumen;
const verifyUrl = env.Verify;
const payUrl = env.Pay;
const messagingUrl = env.Messaging;

export const remoteRoutes = {
  auth: authUrl,
  crm: crmUrl,
  pay: payUrl,
  verify: verifyUrl,
  messaging: messagingUrl,

  userRoles: `${lumenUrl}/api/auth/roles`,
  users: `${lumenUrl}/api/auth/users`,

  resetApiKey: `${lumenUrl}/api/auth/api-key/reset`,
  createApiKey: `${lumenUrl}/api/auth/api-key/create`,
  toggleApiKey: `${lumenUrl}/api/auth/api-key/toggle`,
  apiDocumentation: `${lumenUrl}/api-docs/index.html`,

  contacts: `${crmUrl}/api/crm/contact`,
  contactSearch: `${crmUrl}/api/contact/search`,
  contactById: `${crmUrl}/api/contact/id`,
  contactsPerson: `${crmUrl}/api/crm/person`,
  contactsCompany: `${crmUrl}/api/crm/company`,
  contactsChc: `${crmUrl}/api/crm/person/chc`,
  contactsEmail: `${crmUrl}/api/crm/email`,
  contactsTag: `${crmUrl}/api/crm/tag`,
  contactsUrl: `${crmUrl}/api/crm/url`,
  contactsPhone: `${crmUrl}/api/crm/phone`,
  contactsAddress: `${crmUrl}/api/crm/address`,
  contactsIdentification: `${crmUrl}/api/crm/identification`,

  userProfile: `${lumenUrl}/api/auth/profile`,

  partners: `${lumenUrl}/api/partners`,
  partnersActivateDeactivate: `${lumenUrl}/api/partners/activate-deactivate`,
  partnersCombo: `${lumenUrl}/api/partners-combo`,
  partnerSettings: `${lumenUrl}/api/partner-settings`,

  partnerMembers: `${lumenUrl}/api/partner-members`,
  partnerMemberInvitation: `${lumenUrl}/api/partner-member-invitation`,
  partnerMembersActivateDeactivate: `${lumenUrl}/api/partner-members/activate-deactivate`,

  partnerProductCreate: `${lumenUrl}/api/products/create`,
  partnerProductTopUp: `${lumenUrl}/api/products/top-up`,
  partnerProductCategories: `${lumenUrl}/api/products/categories`,

  creditReference: `${lumenUrl}/api/reference/credit`,
  creditReferenceReport: `${lumenUrl}/api/reference/credit/download-report`,

  // loan data submission
  crtDataSubmissionSample: `${lumenUrl}/api/v1/submission-information/sample/cba`,
  crtDataSubmissionPreview: `${lumenUrl}/api/v1/submission-information/upload`,
  crtDataSubmissionProcess: `${lumenUrl}/api/v1/submission-information/process`,
  crtDataSubmissionHistory: `${lumenUrl}/api/v1/submission-information/history`,
  crtDataSubmission: `${lumenUrl}/api/v1/submission-information`,

  // configuration
  eConsentToggle: `${lumenUrl}/api/v1/e-consent/toggle`,
  eConsentConfigGet: `${lumenUrl}/api/v1/e-consent/get`,
  eConsentConfig: `${lumenUrl}/api/v1/e-consent`,
  experianPartnerConfig: `${lumenUrl}/api/experian-partner-config`,

  manualTopUp: `${lumenUrl}/api/product-purchase-order-manual`,
  ioTecPayTopUp: `${lumenUrl}/api/products/iotec-pay-top-up`,

  // person credit data
  crtCreditData: `${lumenUrl}/api/v1/credit-data/`,
  crtCreditDataSubmission: `${lumenUrl}/api/v1/credit-data/history`,
  downloadDoc: `${lumenUrl}/api/docs/download`,

  purchaseOrderIoTecPayMomoPreview: `${lumenUrl}/api/product-purchase-orders-iotec-pay/mobile-money/preview-top-up`,
  purchaseOrderIoTecPayMomoInitiate: `${lumenUrl}/api/product-purchase-orders-iotec-pay/mobile-money/initiate-payment`,
  purchaseOrderIoTecPayMomoStatus: `${lumenUrl}/api/product-purchase-orders-iotec-pay/mobile-money/status`,

  purchaseOrderIoTecPayWalletSendOtp: `${lumenUrl}/api/product-purchase-orders-iotec-pay/iotec-wallet/send-otp`,
  purchaseOrderIoTecPayWalletVerifyOtp: `${lumenUrl}/api/product-purchase-orders-iotec-pay/iotec-wallet/verify-otp`,
  purchaseOrderIoTecPayWalletInitiate: `${lumenUrl}/api/product-purchase-orders-iotec-pay/iotec-wallet/initiate-payment`,
  purchaseOrderIoTecPayWalletStatus: `${lumenUrl}/api/product-purchase-orders-iotec-pay/iotec-wallet/status`,

  // route for product subscriptions
  productSubscriptions: `${lumenUrl}/api/product-subscriptions`,
  // route for price bands
  productPriceBands: `${lumenUrl}/api/product-price-bands`,
  productPriceBand: `${lumenUrl}/api/products/id`,
  // route for product purchase orders
  productPurchaseOrders: `${lumenUrl}/api/product-purchase-orders`,
  productPurchaseOrderInvoices: `${lumenUrl}/api/product-purchase-orders-invoices`,
  productPurchaseOrderInvoicesCombo: `${lumenUrl}/api/product-purchase-orders-invoices/combo`,

  productPurchaseOrderInvoice: `${lumenUrl}/api/product-purchase-order-invoices`,
  productPurchaseOrderInvoicePay: `${lumenUrl}/api/product-purchase-order-invoices/pay`,
};
